#root {
  width: 100%;
  height: 100vh;
  
}

body{
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Lucida Console", "Courier New", monospace;
  height: 100vh;
  
}


