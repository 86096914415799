
.HomePage{
    background-color: #f0f0f0;
    color:rgb(39, 41, 50);
    margin: 0;
    padding: 0;
    border: 0;
    text-align: center;
    width: 100%;
    height: 93vh;
    font-size: 5.5vh;
}
h5{
    font-size: 12.5vh;
    padding-top: 30vh;
}