
.App {
  text-align: center;
  background-color: #f0f0f0;
  color: #333;
  height: 93vh;
  
}

.App-header {
  background-color: #f0f0f0;
  font-size: larger;
  padding: 20px;
  color: rgb(41, 41, 41);
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.project-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  text-align: left;
  
  transition: transform 0.2s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.project-card h2 {
  font-size: 1.5em;
  margin: 10px 0;
}

.project-card p {
  font-size: 1em;
  color: #666;
  margin: 10px 0;
}

.project-card h3 {
  font-size: 1.2em;
  margin: 10px 0;
  color: #444;
}

.github-link {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.github-icon {
  width: 35px;
  height: 35px;
  margin-right: 8px; /* Add some space between the icon and the text */
}

#github {
  background: #fff;
  float: right;
  padding: 0%;
  margin: 0;
  margin-top: 10px;
}
