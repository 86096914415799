/* src/styles/AboutMe.css */

.about-me {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #ece9e6, #ffffff);
    padding: 20px;
    overflow:visible;

  }
  
  .container {
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    text-align: left;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .profile-photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #333;
  }
  
  h1 {
    font-size: 2.5rem;
    margin: 0;
    color: #333;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #777;
  }
  
  .content {
    margin-top: 20px;
  }
  
  .about-section,
  .experience-section {
    margin-bottom: 30px;
  }
  
  .about-section h2,
  .experience-section h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #444;
    border-bottom: 2px solid #333;
    display: inline-block;
  }
  
  p {
    font-size: 1.15rem;
    line-height: 1.6;
    color: #555;
  }
  
  .experience {
    margin-bottom: 20px;
  }
  
  .experience h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .experience p {
    margin: 0;
    color: #666;
    font-size: 1.2rem;
  }
  .italic{
    font-style: italic;
  }

  ul{
    width: 90%;
    margin-left: 2%;
  }
  li{
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 5px;
    
  }